
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';


import './A11.scss';

export default class A11 extends Component {

  render() {
 
    return (

   
<Grid>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <h1 className="a11">Class Materials</h1>
    <h2 className="a11">General Spanish Brazil Teens A11</h2>
  
    <div class="center"> 
        <Button href='https://generalspanish-brazil-teens-a11u1.comligospanish.com/' className="btn-a11-unit1" >A1.1 Unit 1</Button>   
       
        {/* <Button href='' download className="btn-download" size='medium'>Beginner 2</Button>  */} 
    </div>
    <div class="center"> 
    <Button href='https://generalspanish-brazil-teens-a11u2.comligospanish.com/'  className="btn-a11-unit2" >A1.1 Unit 2</Button> 
    </div>
    <div class="center"> 
    <Button href='https://generalspanish-brazil-teens-a11u3.comligospanish.com/'  className="btn-a11-unit3" >A1.1 Unit 3</Button> 
    </div>
    <div class="center"> 
    <Button href='https://generalspanish-brazil-teens-a11u4.comligospanish.com/'  className="btn-a11-unit4" >A1.1 Unit 4</Button> 
    </div>
    <div class="center"> 
    <Button href='https://generalspanish-brazil-teens-a11u5.comligospanish.com/'  className="btn-a11-unit5" >A1.1 Unit 5</Button> 
    </div>
    

  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
      //   </header>
      //   </div>
      // </div>
    );
  }
}
