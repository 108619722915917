import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';

import './A12.scss';

export default class A12 extends Component {

  render() {
    
    return (
  
      <Grid>
         <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
  <h1 className="a12">Class Materials</h1>
    <h2 className="a12">General Spanish Brazil Teens A12</h2>
  
    <div class="center"> 
        <Button href='https://generalspanish-brazil-teens-a12u1.comligospanish.com/' className="btn-a12-unit1" >A1.2 Unit 1</Button>   
       
        {/* <Button href='' download className="btn-download" size='medium'>Beginner 2</Button>  */} 
    </div> 
    <div class="center"> 
        <Button href='https://generalspanish-brazil-teens-a12u2.comligospanish.com/' className="btn-a12-unit2" >A1.2 Unit 2</Button>   
     
    </div>
    <div class="center"> 
        <Button href='https://generalspanish-brazil-teens-a12u3.comligospanish.com/' className="btn-a12-unit3" >A1.2 Unit 3</Button>   
     
    </div>
    <div class="center"> 
        <Button href='https://generalspanish-brazil-teens-a12u4.comligospanish.com/' className="btn-a12-unit4" >A1.2 Unit 4</Button>   
     
    </div>
    <div class="center"> 
        <Button href='https://generalspanish-brazil-teens-a12u5.comligospanish.com/' className="btn-a12-unit5" >A1.2 Unit 5</Button>   
     
    </div>
 
   
        </Grid.Column>
        <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

</Grid>
    );
  }
}
